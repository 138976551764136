<template>
  <a-spin :spinning="spinning">
    <div class="warpper">
      <a-card>
        <a-row class="article">
          <a-col :span="11">
            <div v-show="isShowTable" class="month">{{ this.titleLast }}年{{ this.titleHeaderMonth }}月销售收款</div>
          </a-col>
          <a-col class="article-deptId" :span="9">
            <a-form-item label="部门">
              <a-tree-select
                style="width: 250px"
                v-model="searchMonth.deptId"
                :dropdownStyle="{ maxHeight: '400px', maxWidth: '250px', overflow: 'auto' }"
                :treeData="depts"
                treeDefaultExpandAll
                @change="handleChangeDept"
                placeholder="部门"
                allowClear
              >
              </a-tree-select>
            </a-form-item>
          </a-col>
          <a-col :span="2">
            <a-month-picker :placeholder="`${this.year.format('YYYY-MM')}`" :disabledDate="disabledDate" @change="onChange" />
          </a-col>
          <!-- 分类收款汇总导出报表 -->
          <a-col style="margin: 12px 0 0 2%" :span="4">
            <downLoad
              method="post"
              api="/api/base/system/report/excelReportDeptCatePayment"
              :params="searchDataObj"
              @downLoadDone="downLoadDone"
              @startDownLoad="startDownLoad"
              :isConfirmLoading="tableLoading"
              name="收款汇总报表.xls"
              >导出</downLoad
            >
          </a-col>
        </a-row>
        <br />
        <div class="month-gmv">
          <a-table
            ref="format"
            v-show="isShowTable"
            :loading="tableLoading"
            :columns="table_config.columns"
            :dataSource="table_config.tableData"
            :rowKey="
              (record, index) => {
                return index
              }
            "
            :row-class-name="rowClassName"
            :pagination="false"
            bordered
          >
            <template slot="titleHeaderMonth">{{ titleHeaderMonth }}月</template>
            <template slot="titleAssessment">{{ titleAssessment }}月考核目标</template>
            <template slot="titleCollection">{{ titleCollection }}月收款</template>
            <template slot="titleVS">{{ titleVS }}月完成率实际VS考核目标</template>
            <template slot="titleSame">{{ titleSame }}月同期</template>
            <template slot="titleCompare">{{ titleCompare }}月同期比</template>
            <template slot="titleSection"
              ><span v-show="isShow">{{ titleSectionInit }}~</span>{{ titleSection }}月</template
            >
            <template slot="titleMiddleAssessment"
              ><span v-show="isShow">{{ titleSectionInit }}~</span>{{ titleMiddleAssessment }}月考核目标</template
            >
            <template slot="titleMiddleCollection"
              ><span v-show="isShow">{{ titleSectionInit }}~</span>{{ titleMiddleCollection }}月实际</template
            >
            <template slot="titleMiddleVS"
              ><span v-show="isShow">{{ titleSectionInit }}~</span>{{ titleMiddleVS }}月完成率实际VS考核目标</template
            >
            <template slot="titleMiddleSame"
              ><span v-show="isShow">{{ titleSectionInit }}~</span>{{ titleMiddleSame }}月同期</template
            >
            <template slot="titleMiddleCompare"
              ><span v-show="isShow">{{ titleSectionInit }}~</span>{{ titleMiddleCompare }}月同期比</template
            >
            <template slot="titleLast">{{ titleLast }}年</template>
            <template slot="MB" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
            <template slot="SJ" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
            <template slot="TQ" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
            <template slot="MBS" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
            <template slot="SJS" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
            <template slot="TQS" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
            <template slot="QN" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
          </a-table>
          <br />
          <div class="report-data">
            <p><strong>月度报表数据</strong></p>
            <p>
              <strong>累计报表数据&emsp;<span style="margin-left: 80%">单位：元</span></strong>
            </p>
          </div>
          <a-table
            ref="format"
            :loading="tableLoading"
            :columns="table_config.cumulativeColumns"
            :dataSource="table_config.cumulativeTableData"
            :rowKey="
              (record, index) => {
                return index
              }
            "
            :row-class-name="rowClassName"
            :pagination="false"
            bordered
          >
            <template slot="mitsubishiAssessment">{{ mitsubishiAssessment }}月考核目标</template>
            <template slot="mitsubishiCollection">{{ mitsubishiCollection }}月收款</template>
            <template slot="mitsubishiVS">{{ mitsubishiVS }}月完成率实际VS考核目标</template>
            <template slot="mitsubishiPeriod">{{ mitsubishiPeriod }}月同期</template>
            <template slot="mitsubishiRatio">{{ mitsubishiRatio }}月同期比</template>
            <template slot="titleLast">{{ titleLast }}年</template>
            <template slot="mitsubishiSectionAssessment"
              ><span v-show="isShow">{{ titleSectionInit }}~</span>{{ mitsubishiSectionAssessment }}月考核目标</template
            >
            <template slot="mitsubishiSectionCollection"
              ><span v-show="isShow">{{ titleSectionInit }}~</span>{{ mitsubishiSectionCollection }}月实际</template
            >
            <template slot="mitsubishiSectionVS"
              ><span v-show="isShow">{{ titleSectionInit }}~</span
              >{{ mitsubishiSectionVS }}月完成率实际VS考核目标</template
            >
            <template slot="mitsubishiSectionPeriod"
              ><span v-show="isShow">{{ titleSectionInit }}~</span>{{ mitsubishiSectionPeriod }}月同期</template
            >
            <template slot="mitsubishiSectionRatio"
              ><span v-show="isShow">{{ titleSectionInit }}~</span>{{ mitsubishiSectionRatio }}月同期比</template
            >
            <template slot="MB" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
            <template slot="SJ" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
            <template slot="TQ" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
            <template slot="MBS" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
            <template slot="SJS" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
            <template slot="TQS" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
            <template slot="QN" slot-scope="item">
              <span>
                {{ item | formatMoney }}
              </span>
            </template>
          </a-table>
          <br />
          <div class="Q1">
            <a-row>
              <a-col :span="21">
                <div class="header-title-text">
                  <strong>{{ title }}</strong>
                </div>
                <div>
                  <p class="header-title-item">
                    <strong><span>单位：元</span></strong
                    ><strong><span>收款</span></strong>
                  </p>
                </div>
              </a-col>
              <a-col :span="2">
                <div>
                  <a-select default-value="Q1一季度报表数据" style="width: 200px" @change="handleChange">
                    <a-select-option value="2"> Q1一季度报表数据 </a-select-option>
                    <a-select-option value="3"> Q2二季度报表数据 </a-select-option>
                    <a-select-option value="4"> Q3三季度报表数据 </a-select-option>
                    <a-select-option value="5"> Q4四季度报表数据 </a-select-option>
                  </a-select>
                </div>
              </a-col>
            </a-row>
            <br />
            <a-table
              ref="format"
              :loading="tableLoading"
              :columns="table_config.QColumns"
              :dataSource="table_config.Q1TableData"
              :rowKey="
                (record, index) => {
                  return index
                }
              "
              :row-class-name="rowClassName"
              :pagination="false"
              bordered
            >
              <template slot="MB" slot-scope="item">
                <span>
                  {{ item | formatMoney }}
                </span>
              </template>
              <template slot="SJ" slot-scope="item">
                <span>
                  {{ item | formatMoney }}
                </span>
              </template>
              <template slot="TQ" slot-scope="item">
                <span>
                  {{ item | formatMoney }}
                </span>
              </template>
              <template slot="titleLast">{{ titleLast }}年</template>
              <template slot="Q"
                ><span>{{ Q }}目标</span></template
              >
              <template slot="QSJ"
                ><span>{{ Q }}实际</span></template
              >
              <template slot="QVS"
                ><span>{{ Q }}完成率实际VS考核目标</span></template
              >
              <template slot="QTQ"
                ><span>{{ Q }}同期</span></template
              >
              <template slot="QB"
                ><span>{{ Q }}同期比</span></template
              >
            </a-table>
          </div>
        </div>
      </a-card>
    </div>
  </a-spin>
</template>
<script>
import ReportTableData from './components/ReportTableData.vue'
import moment from 'moment'
import { formatSale, formatAppoint } from './components/colums.js'
export default {
  name: 'report销售报表',
  components: { ReportTableData },
  data() {
    return {
      title: 'Q1一季度报表数据',
      year: moment(),
      month: moment().month() + 1,
      isShowTable: true,
      isShowQ: true,
      isShow: true,
      spinning: false,
      titleHeaderMonth: JSON.stringify(moment().month() + 1),
      titleAssessment: moment().month() + 1,
      titleCollection: moment().month() + 1,
      titleVS: moment().month() + 1,
      titleSame: moment().month() + 1,
      titleCompare: moment().month() + 1,
      titleSectionInit: '1',
      titleSection: moment().month() + 1,
      titleMiddleAssessment: moment().month() + 1,
      titleMiddleCollection: moment().month() + 1,
      titleMiddleVS: moment().month() + 1,
      titleMiddleSame: moment().month() + 1,
      titleMiddleCompare: moment().month() + 1,
      titleLast: moment().year(),
      // 三菱产品和非三菱产品
      mitsubishiAssessment: JSON.stringify(moment().month() + 1),
      mitsubishiCollection: JSON.stringify(moment().month() + 1),
      mitsubishiVS: JSON.stringify(moment().month() + 1),
      mitsubishiPeriod: JSON.stringify(moment().month() + 1),
      mitsubishiRatio: JSON.stringify(moment().month() + 1),
      mitsubishiSectionAssessment: JSON.stringify(moment().month() + 1),
      mitsubishiSectionCollection: JSON.stringify(moment().month() + 1),
      mitsubishiSectionVS: JSON.stringify(moment().month() + 1),
      mitsubishiSectionPeriod: JSON.stringify(moment().month() + 1),
      mitsubishiSectionRatio: JSON.stringify(moment().month() + 1),
      Q: '',
      tableLoading: false,
      depts: [],
      table_config: {
        columns: [
          {
            title: '部门',
            dataIndex: 'dept',
            align: 'center',
            width: 150,
          },
          {
            slots: {
              title: 'titleHeaderMonth',
            },
            className: 'headerPink',
            children: [
              {
                slots: {
                  title: 'titleAssessment',
                },
                scopedSlots: { customRender: 'MB' },
                dataIndex: 'MB',
                align: 'center',
                key: 2,
                width: 120,
                className: 'headerPink',
                customCell: this.fontText,
              },
              {
                slots: {
                  title: 'titleCollection',
                },
                scopedSlots: { customRender: 'SJ' },
                dataIndex: 'SJ',
                align: 'center',
                key: 3,
                width: 120,
                className: 'headerPink',
                customCell: this.fontText,
              },
              {
                slots: {
                  title: 'titleVS',
                },
                dataIndex: 'MBB',
                align: 'center',
                key: 4,
                width: 120,
                className: 'headerPink',
                customCell: this.salePinkBackground,
              },
              {
                slots: {
                  title: 'titleSame',
                },
                scopedSlots: { customRender: 'TQ' },
                dataIndex: 'TQ',
                align: 'center',
                key: 5,
                width: 120,
                className: 'headerPink',
                customCell: this.fontText,
              },
              {
                slots: {
                  title: 'titleCompare',
                },
                dataIndex: 'TQB',
                align: 'center',
                key: 6,
                width: 120,
                className: 'headerPink',
                customCell: this.salePinkBackground,
              },
            ],
          },
          {
            className: 'headerGreen',
            slots: {
              title: 'titleSection',
            },
            children: [
              {
                slots: {
                  title: 'titleMiddleAssessment',
                },
                scopedSlots: { customRender: 'MBS' },
                dataIndex: 'MBS',
                align: 'center',
                key: 7,
                width: 120,
                className: 'headerGreen',
                customCell: this.fontText,
              },
              {
                slots: {
                  title: 'titleMiddleCollection',
                },
                scopedSlots: { customRender: 'SJS' },
                dataIndex: 'SJS',
                align: 'center',
                key: 8,
                width: 120,
                className: 'headerGreen',
                customCell: this.fontText,
              },
              {
                slots: {
                  title: 'titleMiddleVS',
                },
                dataIndex: 'MBBS',
                align: 'center',
                key: 9,
                width: 120,
                className: 'headerGreen',
                customCell: this.saleGreenBackground,
              },
              {
                slots: {
                  title: 'titleMiddleSame',
                },
                dataIndex: 'TQS',
                align: 'center',
                key: 10,
                width: 120,
                className: 'headerGreen',
                customCell: this.fontText,
                scopedSlots: { customRender: 'TQS' },
              },
              {
                slots: {
                  title: 'titleMiddleCompare',
                },
                dataIndex: 'TQBS',
                align: 'center',
                key: 11,
                width: 120,
                className: 'headerGreen',
                customCell: this.saleGreenBackground,
              },
            ],
          },
          {
            slots: {
              title: 'titleLast',
            },
            className: 'headerOrange',
            children: [
              {
                title: '全年目标',
                dataIndex: 'QN',
                align: 'center',
                width: 120,
                className: 'headerOrange',
                customCell: this.fontText,
                scopedSlots: { customRender: 'QN' },
              },
              {
                title: '全年完成率',
                dataIndex: 'QNB',
                align: 'center',
                width: 120,
                className: 'headerOrange',
              },
            ],
          },
        ],
        tableData: [],
        cumulativeColumns: [
          {
            title: '分类',
            width: 150,
            align: 'center',
            dataIndex: 'channel',
            className: 'leftHeader',
            customRender: (value, row, index) => {
              const obj = {
                children: value,
                attrs: {},
              }
              if (index === 0) {
                obj.attrs.rowSpan = this.mitsubishiAppoint.三菱产品
              }
              if (
                index === this.mitsubishiAppoint.三菱产品 ||
                index === this.mitsubishiAppointSum ||
                index === this.mitsubishiAppointSum + 1
              ) {
                obj.attrs.colSpan = 2
              }
              if (index > 0 && index < this.mitsubishiAppoint.三菱产品) {
                obj.attrs.rowSpan = 0
              }
              if (index === this.mitsubishiAppoint.三菱产品 + this.mitsubishiAppoint.三菱产品小计) {
                obj.attrs.rowSpan = this.mitsubishiAppoint.非三菱产品
              }
              if (
                index > this.mitsubishiAppoint.三菱产品 + this.mitsubishiAppoint.三菱产品小计 &&
                index < this.mitsubishiAppointSum
              ) {
                obj.attrs.rowSpan = 0
              }
              return obj
            },
          },
          // {
          //   title: '序号',
          //    width: 120,
          //   align: 'center',
          //   dataIndex: 'slot',
          //   className: 'leftHeader',
          //   customRender: (value, row, index) => {
          //     const obj = {
          //       children: value,
          //       attrs: {},
          //     }
          //     if (
          //       index === this.mitsubishiAppoint.三菱产品 ||
          //       index === this.mitsubishiAppointSum ||
          //       index === this.mitsubishiAppointSum + 1
          //     ) {
          //       obj.attrs.colSpan = 0
          //     }
          //     return obj
          //   },
          // },
          {
            title: '商品名称',
            width: 150,
            align: 'center',
            dataIndex: 'categoryName',
            className: 'leftHeader',
            customRender: (value, row, index) => {
              const obj = {
                children: value,
                attrs: {},
              }
              if (
                index === this.mitsubishiAppoint.三菱产品 ||
                index === this.mitsubishiAppointSum ||
                index === this.mitsubishiAppointSum + 1
              ) {
                obj.attrs.colSpan = 0
              }
              return obj
            },
          },
          {
            slots: {
              title: 'mitsubishiAssessment',
            },
            align: 'center',
            dataIndex: 'MB',
            key: 4,
            width: 120,
            className: 'leftHeader',
            customCell: this.fontText,
            scopedSlots: { customRender: 'MB' },
          },
          {
            slots: {
              title: 'mitsubishiCollection',
            },
            scopedSlots: { customRender: 'SJ' },
            align: 'center',
            dataIndex: 'SJ',
            key: 5,
            width: 120,
            className: 'leftHeader',
            customCell: this.fontText,
          },
          {
            slots: {
              title: 'mitsubishiVS',
            },
            align: 'center',
            dataIndex: 'MBB',
            key: 6,
            width: 120,
            className: 'headerPink',
            customCell: this.NBackground,
          },
          {
            slots: {
              title: 'mitsubishiPeriod',
            },
            scopedSlots: { customRender: 'TQ' },
            align: 'center',
            dataIndex: 'TQ',
            key: 7,
            width: 120,
            className: 'LightBlue',
            customCell: this.fontText,
          },
          {
            slots: {
              title: 'mitsubishiRatio',
            },
            align: 'center',
            dataIndex: 'TQB',
            key: 8,
            width: 120,
            className: 'headerGreen',
            customCell: this.NGreenBackground,
          },
          {
            slots: {
              title: 'mitsubishiSectionAssessment',
            },
            scopedSlots: { customRender: 'MBS' },
            align: 'center',
            dataIndex: 'MBS',
            key: 9,
            width: 120,
            className: 'leftHeader',
            customCell: this.fontText,
          },
          {
            slots: {
              title: 'mitsubishiSectionCollection',
            },
            scopedSlots: { customRender: 'SJS' },
            align: 'center',
            dataIndex: 'SJS',
            key: 10,
            width: 120,
            className: 'leftHeader',
            customCell: this.fontText,
          },
          {
            slots: {
              title: 'mitsubishiSectionVS',
            },
            align: 'center',
            dataIndex: 'MBBS',
            key: 11,
            width: 120,
            className: 'headerPink',
            customCell: this.NBackground,
          },
          {
            slots: {
              title: 'mitsubishiSectionPeriod',
            },
            align: 'center',
            dataIndex: 'TQS',
            width: 120,
            className: 'LightBlue',
            customCell: this.fontText,
            scopedSlots: { customRender: 'TQS' },
          },
          {
            slots: {
              title: 'mitsubishiSectionRatio',
            },
            align: 'center',
            dataIndex: 'TQBS',
            key: 13,
            width: 120,
            className: 'headerGreen',
            customCell: this.NGreenBackground,
          },
          {
            slots: {
              title: 'titleLast',
            },
            className: 'headerOrange',
            children: [
              {
                title: '全年目标',
                dataIndex: 'QN',
                align: 'center',
                width: 120,
                className: 'headerOrange',
                customCell: this.fontText,
                scopedSlots: { customRender: 'QN' },
              },
              {
                title: '全年完成率',
                dataIndex: 'QNB',
                align: 'center',
                width: 120,
                className: 'headerOrange',
              },
            ],
          },
        ],
        cumulativeTableData: [],
        QColumns: [
          {
            title: '分类',
            dataIndex: 'channel',
            align: 'center',
            key: '1',
            width: 150,
            className: 'leftHeader',
            customRender: (value, row, index) => {
              const obj = {
                children: value,
                attrs: {},
              }
              if (index === 0) {
                obj.attrs.rowSpan = this.QAppoint.三菱产品
              }
              if (index === this.QAppoint.三菱产品 || index === this.QAppointSum || index === this.QAppointSum + 1) {
                obj.attrs.colSpan = 2
              }
              if (index > 0 && index < this.QAppoint.三菱产品) {
                obj.attrs.rowSpan = 0
              }
              if (index === this.QAppoint.三菱产品 + this.QAppoint.三菱产品小计) {
                obj.attrs.rowSpan = this.QAppoint.非三菱产品
              }
              if (index > this.QAppoint.三菱产品 + this.QAppoint.三菱产品小计 && index < this.QAppointSum) {
                obj.attrs.rowSpan = 0
              }
              return obj
            },
          },
          // {
          //   title: '序号',
          //   dataIndex: 'number',
          //   align: 'center',
          //   key: '2',
          //    width: 120,
          //   className: 'leftHeader',
          //   customRender: (value, row, index) => {
          //     const obj = {
          //       children: value,
          //       attrs: {},
          //     }
          //     if (index === this.QAppoint.三菱产品 || index === this.QAppointSum || index === this.QAppointSum + 1) {
          //       obj.attrs.colSpan = 0
          //     }
          //     return obj
          //   },
          // },
          {
            title: '商品名称',
            dataIndex: 'categoryName',
            align: 'center',
            key: '3',
            width: 120,
            className: 'leftHeader',
            customRender: (value, row, index) => {
              const obj = {
                children: value,
                attrs: {},
              }
              if (index === this.QAppoint.三菱产品 || index === this.QAppointSum || index === this.QAppointSum + 1) {
                obj.attrs.colSpan = 0
              }
              return obj
            },
          },
          {
            slots: {
              title: 'Q',
            },
            // title: 'Q1目标',
            dataIndex: 'MB',
            align: 'center',
            key: '4',
            width: 120,
            className: 'leftHeader',
            customCell: this.fontText,
            scopedSlots: { customRender: 'MB' },
          },
          {
            slots: {
              title: 'QSJ',
            },
            dataIndex: 'SJ',
            align: 'center',
            key: '5',
            width: 120,
            className: 'leftHeader',
            customCell: this.fontText,
            scopedSlots: { customRender: 'SJ' },
          },
          {
            slots: {
              title: 'QVS',
            },
            dataIndex: 'MBB',
            align: 'center',
            key: '6',
            width: 120,
            className: 'headerPink',
            customCell: this.QBackground,
          },
          {
            slots: {
              title: 'QTQ',
            },
            dataIndex: 'TQ',
            align: 'center',
            width: 120,
            className: 'LightBlue',
            customCell: this.fontText,
            scopedSlots: { customRender: 'TQ' },
          },
          {
            slots: {
              title: 'QB',
            },
            dataIndex: 'TQB',
            align: 'center',
            width: 120,
            className: 'headerGreen',
            customCell: this.QGreenBackground,
          },
          {
            slots: {
              title: 'titleLast',
            },
            className: 'headerOrange',
            children: [
              {
                title: '全年目标',
                dataIndex: 'QN',
                align: 'center',
                width: 120,
                className: 'headerOrange',
                customCell: this.fontText,
                scopedSlots: { customRender: 'QN' },
              },
              {
                title: '全年完成率',
                dataIndex: 'QNB',
                align: 'center',
                width: 120,
                className: 'headerOrange',
              },
            ],
          },
        ],
        Q1TableData: [],
      },
      searchSale: {
        date: moment().format('YYYY-MM'),
        deptId: '',
      },
      searchMonth: {
        date: moment().format('YYYY-MM'),
        type: '1',
        deptId: '',
      },
      searchData: {
        date: moment().format('YYYY-MM'),
        type: '2',
        deptId: '',
      },
      saleLength: null,
      mitsubishiAppoint: {},
      mitsubishiAppointSum: null,
      QAppoint: {},
      QAppointSum: null,
    }
  },
  created() {
    this.getDeptList()
    this.getSale()
    this.getChoiceDataMonth()
    this.getChoiceData()
  },
  computed: {
    searchDataObj() {
      const obj = {
        ...this.searchSale,
      }
      return obj
    },
  },
  methods: {
    moment,
    downLoadDone() {
      this.spinning = false
    },
    startDownLoad() {
      this.spinning = true
    },
    handleChange(value) {
      this.searchData.type = value
      this.getChoiceData()
    },
    handleChangeDept(ID, title) {
      ID ? (this.isShowTable = false) : (this.isShowTable = true)
      this.searchMonth.deptId = ID
      this.searchData.deptId = ID
      this.searchSale.deptId = ID
      this.dept = title[0]
      this.getSale()
      this.getChoiceDataMonth()
      this.getChoiceData()
    },
    onChange(date) {
      if(!date){
        date = moment();
      }
      this.titleHeaderMonth = date.month() + 1
      this.titleAssessment = date.month() + 1
      this.titleCollection = date.month() + 1
      this.titleVS = date.month() + 1
      this.titleSame = date.month() + 1
      this.titleCompare = date.month() + 1
      //
      this.titleSection = date.month() + 1
      if (this.titleSection == 1) {
        this.isShow = false
      } else {
        this.isShow = true
      }
      //
      this.titleMiddleAssessment = date.month() + 1
      this.titleMiddleCollection = date.month() + 1
      this.titleMiddleVS = date.month() + 1
      this.titleMiddleSame = date.month() + 1
      this.titleMiddleCompare = date.month() + 1
      this.titleLast = date.year()
      //
      this.mitsubishiAssessment = date.month() + 1
      this.mitsubishiCollection = date.month() + 1
      this.mitsubishiVS = date.month() + 1
      this.mitsubishiPeriod = date.month() + 1
      this.mitsubishiRatio = date.month() + 1
      this.mitsubishiSectionAssessment = date.month() + 1
      this.mitsubishiSectionCollection = date.month() + 1
      this.mitsubishiSectionVS = date.month() + 1
      this.mitsubishiSectionPeriod = date.month() + 1
      this.mitsubishiSectionRatio = date.month() + 1
      this.searchSale.date = date.format('YYYY-MM')
      this.searchMonth.date = date.format('YYYY-MM')
      this.searchData.date = date.format('YYYY-MM')
      this.getChoiceDataMonth()
      this.getSale()
      this.getChoiceData()
    },
    getDeptList() {
      this.axios.get('/api/base/system/department/all').then((res) => {
        const data = res.body
        this.buildtree(data, this.depts, 0)
      })
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          var child = {
            key: item.id,
            value: item.id, // value是给modal的select用的，2者属性不一样
            title: item.name,
            scopedSlots: { title: 'title' },
            children: [],
          }
          this.buildtree(list, child.children, item.id)
          if (child.children.length === 0) {
            delete child.children
          }
          arr.push(child)
        }
      })
    },
    getSale() {
      this.tableLoading = true
      this.axios
        .post('/api/base/system/report/getReportDeptPayment', this.searchSale)
        .then((res) => {
          if (res) {
            this.tableLoading = false
            this.table_config.tableData = res.body
            this.saleLength = formatSale(res.body)
          }
        })
        .catch(() => {
          this.tableLoading = false
        })
    },
    getChoiceDataMonth() {
      this.tableLoading = true
      this.axios
        .post('/api/base/system/report/getReportDeptCatePayment', this.searchMonth)
        .then((res) => {
          if (res) {
            this.tableLoading = false
            this.table_config.cumulativeTableData = res.body
            this.mitsubishiAppoint = formatAppoint(res.body)
            this.mitsubishiAppointSum =
              this.mitsubishiAppoint.非三菱产品 + this.mitsubishiAppoint.三菱产品 + this.mitsubishiAppoint.三菱产品小计
          }
        })
        .catch(() => {
          this.tableLoading = false
        })
    },
    getChoiceData() {
      this.tableLoading = true
      this.axios
        .post('/api/base/system/report/getReportDeptCatePayment', this.searchData)
        .then((res) => {
          if (res) {
            this.tableLoading = false
            this.table_config.Q1TableData = res.body
            this.QAppoint = formatAppoint(res.body)
            this.QAppointSum = this.QAppoint.非三菱产品 + this.QAppoint.三菱产品 + this.QAppoint.三菱产品小计
            switch (this.searchData.type) {
              case '2':
                this.title = 'Q1一季度报表数据'
                this.Q = 'Q1'
                break
              case '3':
                this.title = 'Q2二季度报表数据'
                this.Q = 'Q2'
                break
              case '4':
                this.title = 'Q3三季度报表数据'
                this.Q = 'Q3'
                break
              case '5':
                this.title = 'Q4四季度报表数据'
                this.Q = 'Q4'
                break
            }
          }
        })
        .catch(() => {
          this.tableLoading = false
        })
    },
    disabledDate(current) {
      // 禁用时间选择区间
      // return current && current < moment().endOf('day');
      return current && current > Date.now()
    },
    salePinkBackground(record, index) {
      if (index >= 0 && index < this.saleLength) {
        return {
          style: {
            'background-color': '#fce4d6',
          },
        }
      }
    },
    saleGreenBackground(record, index) {
      if (index >= 0 && index < this.saleLength) {
        return {
          style: {
            'background-color': '#e2efda',
          },
        }
      }
    },
    NBackground(record, index) {
      if (
        (index >= 0 && index < this.mitsubishiAppoint.三菱产品) ||
        (index >= this.mitsubishiAppoint.三菱产品 + 1 && index < this.mitsubishiAppointSum)
      ) {
        return {
          style: {
            'background-color': '#fce4d6',
          },
        }
      }
    },
    NGreenBackground(record, index) {
      if (
        (index >= 0 && index < this.mitsubishiAppoint.三菱产品) ||
        (index >= this.mitsubishiAppoint.三菱产品 + 1 && index < this.mitsubishiAppointSum)
      ) {
        return {
          style: {
            'background-color': '#e2efda',
          },
        }
      }
    },
    QBackground(record, index) {
      if (
        (index >= 0 && index < this.QAppoint.三菱产品) ||
        (index >= this.QAppoint.三菱产品 + 1 && index < this.QAppointSum)
      ) {
        return {
          style: {
            'background-color': '#fce4d6',
          },
        }
      }
    },
    QGreenBackground(record, index) {
      if (
        (index >= 0 && index < this.QAppoint.三菱产品) ||
        (index >= this.QAppoint.三菱产品 + 1 && index < this.QAppointSum)
      ) {
        return {
          style: {
            'background-color': '#e2efda',
          },
        }
      }
    },
    fontText() {
      return {
        style: {
          'text-align': 'right',
        },
      }
    },
    rowClassName(record) {
      const className = 'light-row'
      const classNum = 'light-row'
      const classUN = 'light-row'
      const classUNum = 'light-row'
      if (record.februaryAim === '小计') {
        return {
          className,
        }
      } else if (record.februaryAim === '总计') {
        return {
          classNum,
        }
      } else if (
        record.areaName === '合计' ||
        record.channel === '合计' ||
        record.dept === '合计' ||
        record.categoryName === '合计'
      ) {
        return {
          classUN,
        }
      } else if (record.categoryName === '小计' || record.categoryName === '三菱产品小计') {
        return {
          classUNum,
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.month {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
}
.month_gmv {
  margin-top: 20px;
}
.report-data {
  display: flex;
}
.report-data > p {
  width: 50%;
}
.article {
  display: flex;
  align-items: center;
}
.header-title-item {
  display: flex;
  justify-content: space-between;
  width: 200px;
}
.header-title-text {
  font-size: 20px;
}
.article-deptId {
  margin-top: 1.5rem;
}
::v-deep .article-deptId .ant-form-item {
  display: flex;
}
::v-deep .article-deptId .ant-form-item * {
  flex: 1;
}
::v-deep .ant-table-thead > tr > th.headerPink {
  background-color: #fce4d6;
}
::v-deep .ant-table-thead > tr > th.headerGreen {
  background-color: #e2efda;
}
::v-deep .ant-table-thead > tr > th.headerOrange {
  background-color: #ffe699;
}
::v-deep .ant-table-thead > tr > th.leftHeader {
  background-color: #fff2cc;
}
::v-deep .ant-table-thead > tr > th.LightBlue {
  background-color: #ddebf7;
}
::v-deep .ant-table-tbody > .classOneTitle {
  background-color: #b4c6e7;
}
::v-deep .ant-table-tbody > .className {
  background-color: #b4c6e7;
}
::v-deep .ant-table-tbody > .classNum {
  background-color: #ffc000;
}
::v-deep .ant-table-tbody > .classUN {
  background-color: #b4c6e7;
}
::v-deep .ant-table-tbody > .classUNum {
  background-color: #d9d9d9;
}
</style>